.g-recaptcha {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

label {
  font-weight: bold;
}

.feedback {
  &.success {
    color: green;
  }

  &.error {
    color: red;
  }
}

input {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}

textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}

#contact-submit {
  cursor: pointer;
  color: black;
  width: 100%;
  border: none;
  border: 1px solid #ccc;
  margin: 5px 0 5px 0;
  padding: 10px;
  font-size: 15px;
}

#contact-submit.disabled {
  cursor: not-allowed;
  color: #ccc;
}
