.mobile-search {
  position: absolute;
  display: block;
  visibility: hidden;
  top: 24px;
  left: 20px;
  color: white;
  z-index: 1000;
  font-size: 1.1rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.mobile-search.hide {
  display: none !important;
}

.mobile-search.open {
  visibility: visible;
  opacity: 1;
}
.fa.fa-search { vertical-align: top; }

.mobile-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 15px;
  position: absolute;
  z-index: 100;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  margin-top: -10%;

  li {
    margin: 0;
    margin-bottom: 15px;
  }
}

.open .mobile-menu {
  opacity: 1;
}

.desktop-menu {
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    flex: 1;
    max-width: 650px;
  }
}

/* menu button */
.hamburger {
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: relative;
  z-index: 101;

  > span {
    background-color: #fff;
    border-radius: 1px;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 75%;
    top: 50%;
    margin: -1px 0 0 -15px;
    transition: height 100ms;

    &::after,
    &::before {
      content: '';
      background-color: #fff;
      border-radius: 1px;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      transition: all 200ms;
    }

    &::after {
      top: -7px;
    }

    &::before {
      bottom: -7px;
    }
  }

  &.hide {
    display: none !important;
  }

  &.open {
    > span {
      height: 0;

      &::after,
      &::before {
        top: 1px;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }
  }
}

// ---------------------------
// Circular Splash Background
// ---------------------------
.splash {
  position: absolute;
  top: 30px;
  right: 23px;
  width: 1px;
  height: 1px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #2c3e50;

    // screen diameter can be 142vmax at most,
    // circle needs to be twice that size to cover it
    width: 284vmax;
    height: 284vmax;
    top: -142vmax;
    left: -142vmax;
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
    // transition: all 2000ms;
    // transition: all 1s ease-in-out;
    // -webkit-transition: all 0.5s;
    // -moz-transition: all 0.5s;
    // -o-transition: all 0.5s;
    // transition: all 0.5s;

    // will-change tells the browser we plan to
    // animate this property in the near future
    will-change: transform;
  }
}

// ---------------------------
// Active State
// ---------------------------
// .nav:target,
.open {
  > .splash::after {
    transform: scale(1);
  }
}

@media only screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }
}
