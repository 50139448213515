/* Color Theme Swatches in Hex */
.gold-1 { color: #f7c657; }
.gold-2 { color: #dea200; }
.gold-3 { color: #f5bd1f; }
.blue-1 { color: #2c3e50; }
.blue-2 { color: #1c252e; }

.bg-gold-1 { background-color: #f7c657; }
.bg-gold-2 { background-color: #dea200; }
.bg-gold-3 { background-color: #f5bd1f; }
.bg-blue-1 { background-color: #2c3e50; }
.bg-blue-2 { background-color: #1c252e; }

* {
  box-sizing: border-box;
}

#root {
  display: block;
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  min-width: 320px;
  font-size: 12px;
}

p {
  margin-top: 0;
}

@media only screen and (min-width: 680px) {
  .App {
    font-size: 14px;
  }
}

@media only screen and (min-width: 800px) {
  .App {
    font-size: 16px;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
